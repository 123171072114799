<template>
    <div class="section">
        <h1 class="overskrift-hvit">{{ post.title }}</h1>

        <div class="columns">
            <div class="column is-hidden-desktop">
                <span class="is-size-6 is-italic" v-for="tag in post.tags" :key="tag.tags">
                    {{tag}}
                </span>  
                <span class="is-size-6 is-italic"> // Publisert {{ moment(post.date).format('LL') }}</span> 

                <div v-if="!post.videoLink" style="margin-top:0.5em;">
                    <img :src="post.coverImage.url" />
                </div>                
            </div>
            <div class="column is-two-thirds is-full-mobile">
                <span class="is-size-6 is-italic is-hidden-mobile" v-for="tag in post.tags" :key="tag.tags">
                    {{tag}}
                </span>

                <span class="is-size-6 is-italic is-hidden-mobile"> // Publisert {{ moment(post.date).format('LL') }}</span>                

                <div v-if="post.videoLink">
                    <div style="padding:56.25% 0 0 0;position:relative; margin-top:1em;">
                        <iframe 
                            :src="post.videoLink" 
                            frameborder="0" 
                            allow="autoplay; fullscreen; picture-in-picture" 
                            allowfullscreen 
                            style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                            title="Showreel 2021">
                        </iframe>
                    </div>
                </div>
                
                <div v-html="post.content.html"></div>
            </div>
            <div class="column is-one-third is-hidden-mobile">
                <div v-if="!post.videoLink">
                    <img :src="post.coverImage.url" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Query from '../graphql/singlePost.gql'

export default {
  name: 'Oppskrift',
  components: {
  },
  data(){
    return{
        post: '',
        slug: this.$route.params.slug
    }
  },
  apollo: {
      post: {
          query: Query,
          variables() {
              return {
                  slug: this.slug                  
              }
          }
      }
  }
}

</script>
