import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SinglePost from '../views/SinglePost.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/oppskrifter',
    name: 'Oppskrifter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Oppskrifter.vue')
  },
  {
    path: '/om',
    name: 'Om',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "om" */ '../views/Om.vue')
  },  
  {
    // Denne må være sist i lista
    path: '/:slug',
    name: 'Oppskrift',
    component: SinglePost,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
