<template>
  <div class="section bak">   
    <p class="is-size-3 is-size-4-mobile">Du har kommet til en uambisiøs og (foreløpig) underutviklet side om mat og matlaging - med et snev <a href="wikipeida">lokalfokus</a>. Her finner du alle oppskriftene som ble laget til <a href="https://www.generalhotell.no" target="_blank">General Hotell</a> sin blogg mellom 2008 og 2020 samt mine nye skriblerier etter dette. Nettstedet vil alltid være i Beta. <a href="/om" target="_self">Les mer om prosjektet her.</a></p>

  <h2 class="overskrift-hvit" style="margin-bottom:0.5em;">Siste oppskrifter</h2>

    <ApolloQuery :query="require('../graphql/frontPage.gql')">
      <template slot-scope="{ result: { data } } ">
        <div v-if="data">                            
          <div v-for="post in data.posts" :key="post.id">
            <div class="columns">              
              <div class="column is-one-third">
                <figure class="image is-16by9" width="400" >
                  <router-link :to="post.slug">
                    <img :src="post.coverImage.url" :alt="post.title" />
                  </router-link>
                </figure>
              </div>
              <div class="column is-two-thirds">               
                  <h3 class="overskrift-hvit">
                    <router-link :to="post.slug">
                      {{post.title}}
                    </router-link>
                  </h3>

                  <p class="utenAvsnitt is-size-4 is-size-5-mobile">
                    {{post.excerpt}}
                  </p>

                  <span class="is-size-6 is-italic" v-for="tag in post.tags" :key="tag.tags">
                    {{tag}}
                  </span>

                  <span class="is-size-6 is-italic"> // Publisert {{ moment(post.date).format('LL') }}</span>

                  <p class="utenAvsnitt is-size-5 is-size-6-mobile">
                    <router-link :to="post.slug">
                      Les mer
                    </router-link>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ApolloQuery>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>

<style scoped>
.table {
  background-color: #000000;
}

.utenAvsnitt {
  margin-bottom: 0.2em !Important;
  margin-top: 0.2em !Important;
}
</style>
