import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { createProvider } from './vue-apollo'
import moment from 'moment'
import vueVimeoPlayer from 'vue-vimeo-player'

Vue.prototype.moment = moment
moment.locale('nb');
Vue.config.productionTip = false

Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(vueVimeoPlayer)

Vue.component("my-header", Header);
Vue.component("my-footer", Footer);

new Vue({
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
