<template>
	<nav class="navbar" role="navigation" aria-label="main navigation">
		<div class="navbar-brand">
            <div class="overskrift">
                JonasMat.no
            </div>

            <a role="button" class="navbar-burger burger" data-target="navMenu" aria-label="menu" aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

		<div class="navbar-menu" id="navMenu" role="navigation" aria-label="main navigation">
			<div class="navbar-start">
                <a class="navbar-item">
                    <router-link 
                        to="/" 
                        class="has-text-weight-bold has-text-black"
                        style="margin-left:40px;"
                    >
                        Hjem
                    </router-link>
                </a>
                <a class="navbar-item">
                    <router-link 
                        to="/oppskrifter" 
                        class="has-text-weight-bold has-text-black"
                    >
                        Oppskrifter
                    </router-link>
                </a>      
                <a class="navbar-item">
                    <router-link 
                        to="/om" 
                        class="has-text-weight-bold has-text-black"
                    >
                        Om
                    </router-link>
                </a>     
               
            </div>		
			<!-- Høyre box -->
			<div class="navbar-end is-hidden-mobile">
				<div class="navbar-item">
                    <div class="buttons">                        
                        <a class="button is-grey" href="https://www.paraproduksjoner.no" target="_blank">Paraproduksjoner</a>
                    </div>				
				</div>
			</div>
        </div>
    </nav>
</template>
 
<script>


export default {
    data: function() {
        return {
		}
	}
}

// Burger
document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});

</script>



